<template>
  <div class="container">
    <!-- 新增规格 -->
    <div class="header-button">
      <el-button @click="dialogSort = true" type="primary" class="el-icon-plus">新增规格</el-button>
    </div>
    
    <!-- 分类列表 -->
    <el-table class="sort-table"
      row-key="id"
      :data="sortList"
      :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="title" label="规格类目名称"></el-table-column>
      <el-table-column prop="num" label="包含规格">
        <template slot-scope="scope">
          <template v-for="(item, index) in 1">
            <el-tag :key="index">{{scope.row.title}}</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-button @click="openEdit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加/修改规格弹窗 -->
    <el-dialog title="新增/修改规格" width="1000px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="dialogSort">
      <el-form label-width="80px" :model="form">
        <el-form-item label="类目名称">
          <el-input v-model="form.title" placeholder="请输入类目名称"></el-input>
        </el-form-item>
      </el-form>
      <el-table class="sort-table"
        row-key="id"
        :data="sortSpecs"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <!-- 规格名称 -->
        <el-table-column label="规格名称" width="200px">
          <template slot-scope="scope">
            <el-input v-model="scope.row.title" placeholder="请输入类目名称"></el-input>
          </template>
        </el-table-column>
        <!-- 规格项 -->
        <el-table-column label="商品数量">
          <template slot-scope="scope">
            <el-tag v-for="tag in dynamicTags" :key="tag" type="info" class="dialog-tag"
              closable 
              :disable-transitions="false"
              @close="handleClose(tag, scope)">
              {{tag}}
            </el-tag>
            <el-input class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" type="text" @click="showInput">添加规格项</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70px">
          <template slot-scope="scope">
            <i size="small" class="el-icon-delete" style="color:#ff0000;cursor: pointer;"></i>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogSort = false">取 消</el-button>
        <el-button type="primary" @click="dialogSort = false">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return {
      sortList: [ // 分类列表数据
        {id: 1, title: "旗舰版商城多规格", num: 0},
        {id: 2, title: "旗舰版商城", num: 0},
      ],
      dialogSort: false, //添加/修改分类弹窗
      form: {
        title: "",
        parent: {
          id: 1,
          name: "父级分类"
        },
        img: ""
      },
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      sortSpecs: [ // 分类列表数据
        {id: 1, title: "测试类目1", num: 0},
        {id: 2, title: "测试类目2", num: 0},
        {id: 3, title: "测试类目3", num: 0},
        {id: 4, title: "测试类目4", num: 0},
        {id: 5, title: "测试类目5", num: 0}
      ],
      dynamicTags: ['标签一', '标签二', '标签三'],
      inputVisible: false,
      inputValue: ''
    }
  },
  mounted(){
    
  },
  methods: {
    // 打开编辑分类弹窗
    openEdit(item){
      this.form.title = item.title;
      this.dialogSort = true;
    },

    handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.dynamicTags.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      }
  }
}
</script>

<style lang="scss" scoped>
.header-button{
  text-align: right;
}
.sort-table{
  width: 100%;
  margin-top: 20px;
}
.dialog-tag{
  margin-right: 5px;
}
.input-new-tag{
  width: 100px;
}
</style>